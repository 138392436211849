






















































import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import store from "@/store";
import {LoginRequest} from "@/dto/auth";

@Component({
  async beforeRouteEnter(to: Route, from: Route, next) {
    let userToken = store.state.userToken;
    
    if (userToken) {
      // если переход из нашего приложения, то отменяем переход
      if (from.matched.length) {
        console.log("from.matched: " + from)
        next();
      } else {
        const path = to.query.redirect as string;
        console.log("else: " + path)
        next(path ? { path } : { name: "home" });
      }
    } else {
      next();
    }
  },
})
export default class LoginView extends Vue {
  public loginRequest: LoginRequest = {
    login: null,
    password: null
  };
  
  public error: string | null = null;
  private saveUser = false;
  public returnBack(){
    this.$router.push("/");
  }
  public async signIn() {
    try {
      console.log("Start login");
      
      let loginResult = await this.$apiClient.login(this.loginRequest);
      if (!loginResult.isSuccess) {
        this.error = loginResult.message;
        return;
      }

      this.$store.commit("setUserToken", loginResult.data);
      const path = this.$route.query.redirect as string;
      if (this.saveUser){
        this.$store.commit("saveUserAndPass", this.loginRequest);
      }
      await this.$router.replace(path ? { path } : { name: "wishes" });
    } catch (e) {
      if (typeof e === "string") {
        this.error = e
      } else if (e instanceof Error) {
        e.message
      }else{
        this.error = "UNKNOWN ERROR"  
      }
      
    }
  }
}
