













import {Component, Vue} from 'vue-property-decorator';
import store from "@/store";
import {MenuItem} from "@/dto/menu";

@Component
export default class MenuControl extends Vue {
  public isLogined(): boolean {
    return store.getters.isLogined;
  }

  public getMenuItems(): Array<MenuItem> | null{
    return [
      {name: "Ваши желания", route: "wishes"},
      {name: "Друзья", route: "friends"},
      {name: "Запросы в друзья", route: "requests"}
    ]
  }
}
