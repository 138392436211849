import Vue from 'vue'
import Vuex from 'vuex'
import {LoginRequest, UserAndPass, UserData} from "@/dto/auth";

Vue.use(Vuex)

const storedUserToken = localStorage.getItem('userToken');
const storedUserAndPass = localStorage.getItem('userAndPass');


export default new Vuex.Store({
  state: {
    userToken: storedUserToken,
    userData: new UserData(storedUserToken),
    userAndPass: new UserAndPass(storedUserAndPass)
  },
  mutations: {
    saveUserAndPass(state, userAndPass){
      state.userAndPass = userAndPass;
      localStorage.setItem('userAndPass', JSON.stringify(userAndPass));
    },
    deleteUserAndPass(state){
      state.userAndPass = new UserAndPass(null);
      localStorage.removeItem('userAndPass');
    },
    setUserToken (state, token) {
      state.userToken = token;
      state.userData = new UserData(token);
      localStorage.setItem('userToken', token);
    },
    deleteUserData (state) {
      state.userToken = null;
      state.userData = new UserData(null);
      localStorage.removeItem('userToken');
    },
  },
  getters: {
    isLogined(state): boolean {
      return state.userData.guid !== null;
    },
    userName(state): string | null {
      return state.userData.name;
    },
    userId(state): string | null {
      return state.userData.guid;
    },
    userToken(state): string | null{
      return state.userToken;
    },
    userAndPass(state): UserAndPass | null{
      return state.userAndPass;
    }
  },
});

