

























import { Component, Vue } from 'vue-property-decorator';
import {UserData} from "@/dto/auth";
import store from "@/store";

@Component
export default class HeaderControl extends Vue {

  public getUserName(): string | null{
    return store.getters.userName;
  }
  
  public isLogined(): boolean {
    return store.getters.isLogined;
  }

  public logout() {
    store.commit("deleteUserData");
    this.$router.push("/")
  }

  public login() {
    this.$router.push("login")
  }

  public singing() {
    this.$router.push("register")
  }

}
