































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AboutView extends Vue {
    public dismissSecs = 10;
    public dismissCountDown = 0;
    public showDismissibleAlert = false;

    public countDownChanged(dismissCountDown: number) {
        this.dismissCountDown = dismissCountDown
    }

    public showAlert() {
        this.dismissCountDown = this.dismissSecs
    }
}
