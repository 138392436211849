import IAuthApi from "@/api/auth";
import {LoginRequest} from "@/dto/auth";

import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import TaskResultError, {TaskResult, TaskResultBase} from "@/dto/taskResult";
import {Vue} from "vue-property-decorator";
import {CreateUserRequest, User} from "@/dto/user";
import IWishesApi from "@/api/wishes";
import {NewWish, Wish} from "@/dto/wishes";
import {Store} from "vuex";
import IFriendsApi from "@/api/friends";
import {FriendRequestInfo, SendRequestData, UserInfo} from "@/dto/friends";



interface ICoreApi extends IAuthApi, IWishesApi, IFriendsApi {}

export default class ApiClient implements ICoreApi {
    constructor(store: Store<any>) {
        this._store = store; 
    }

    baseUrl(): string{
        if(process.env.VUE_APP_CORE_URL)
            return process.env.VUE_APP_CORE_URL;
        
        if(process.env.BASE_URL)
            return process.env.BASE_URL;
        
        return "";
    }
    
    addNew(wish: NewWish): Promise<TaskResult<string>> {
        return this._http({
            method: 'put',
            url: `${this.baseUrl()}/api/Wish/New`,
            data: wish
        });
    }

    remove(wishGuid: string): Promise<TaskResult<string>> {
        return this._http({
            method: 'delete',
            url: `${this.baseUrl()}/api/Wish/Delete`,
            params: { id: wishGuid }
        });
    }

    book(wishGuid: string): Promise<TaskResultBase> {
        return this._http({
            method: 'post',
            url: `${this.baseUrl()}/api/Wish/Book`,
            params: { id: wishGuid }
        });
    }
    unBook(wishGuid: string): Promise<TaskResultBase> {
        return this._http({
            method: 'post',
            url: `${this.baseUrl()}/api/Wish/UnBook`,
            params: { id: wishGuid }
        });
    }
    
    
    private _store: Store<any>;
    load(): Promise<TaskResult<Array<Wish>>> {
        return this._http({
            method: 'get',
            url: `${this.baseUrl()}/api/Wish/Wishes`,
        });
    }

    login(params: LoginRequest): Promise<TaskResult<string>> {
        return this._http({
            method: 'post',
            url: `${this.baseUrl()}/api/Auth/login`,
            data: params,
        });
    }

    newUser(params: CreateUserRequest): Promise<TaskResult<User>>{
        return this._http({
            method: 'put',
            url: `${this.baseUrl()}/api/User/new`,
            data: params,
        });
    }

    
    
    async _http(requestConfig: AxiosRequestConfig): Promise<any>{
        let httpResponse: AxiosResponse;

        if (this._store.getters.isLogined) {
            requestConfig.headers = {
                Authorization: "Bearer " + this._store.getters.userToken
            }
        }
        
        try {
            httpResponse = await Vue.axios.request(requestConfig);
        } catch (e) {
            console.log('HTTP Response error', e);
            if (e instanceof  AxiosError){
                const userAndPass = this._store.getters.userAndPass;
                if (e.response?.status === 401 && userAndPass){
                    const loginResult = await this.login(userAndPass)
                    if (loginResult.isSuccess) {
                        this._store.commit("setUserToken", loginResult.data);
                        return this._http(requestConfig);
                    }
                }
                    
                return new TaskResultError(`HTTP Response error: ${e.message}`); 
            }
            
            return new TaskResultError(`HTTP Response error: ${e}`); 
        }

        if (httpResponse.status !== 200) {
            return new TaskResultError(`HTTP STATUS = ${httpResponse.status}`);
        }
                
        return httpResponse.data;
    }

    friends(): Promise<TaskResult<Array<UserInfo>>> {
        return this._http({
            method: 'get',
            url: `${this.baseUrl()}/api/Friend/Friends`,
        });
    }

    receivedFriendRequests(): Promise<TaskResult<Array<FriendRequestInfo>>> {
        return this._http({
            method: 'get',
            url: `${this.baseUrl()}/api/Friend/ReceivedFriendRequests`,
        });
    }

    acceptFriendRequests(friendId: string):Promise<TaskResultBase>{
        return this._http({
            method: 'post',
            url: `${this.baseUrl()}/api/Friend/AcceptFriendRequests`,
            params: { from: friendId }
        });
    }
    
    search(email: string): Promise<TaskResult<UserInfo>> {
        return this._http({
            method: 'get',
            url: `${this.baseUrl()}/api/Friend/Search`,
            params: { email } 
        });
    }

    sendFriendRequest(data: SendRequestData): Promise<TaskResultBase> {
        return this._http({
            method: 'post',
            url: `${this.baseUrl()}/api/Friend/SendFriendRequest`,
            data: data
        });
    }

    getFriendWishes(friendId: string): Promise<TaskResult<Array<Wish>>>{
        return this._http({
            method: 'get',
            url: `${this.baseUrl()}/api/Friend/GetFriendWishes`,
            params: { friendId }
        });
    }
}