



































































































import store from "@/store";
import {Component, Vue} from "vue-property-decorator";
import {Wish} from "@/dto/wishes";
import {UserInfo} from "@/dto/friends";

@Component({
  components: {},
  
  created(this: FiendsView) {
    this.loadFriends();
  },
})
export default class FiendsView extends Vue {
  public userName(): string {
    return  store.getters.userName
  }
  
  public friends: Array<UserInfo> | null = null;
  public wishes: Array<Wish> | null = null;
  public error: string | null = null;
  
  public async loadFriends(){
    let response = await this.$apiClient.friends();
    if (!response.isSuccess){
      this.error = response.message;
      return;
    }

    this.error = null; 
    this.friends = response.data;
  }
  
  public isAllowBook(id: string | null): boolean{
    return !id;     
  }

  private bookError: string | null = null;
  public async book(id: string){
    let response = await this.$apiClient.book(id)
    if (!response.isSuccess){
      this.bookError = response.message;
      return;
    }
    
    this.bookError = null;
    await this.loadWishes(this.currentFriendId!);
  }

  public async unbook(id: string){
      let response = await this.$apiClient.unBook(id);
      if (!response.isSuccess){
        this.bookError = response.message;
        return;
      }

      this.bookError = null;
      await this.loadWishes(this.currentFriendId!);
    }
  
  public isBookedByUser(id: string | null): boolean{
    return id === store.getters.userId;
  }
  
  public getStyle(id: string | null){
    if (id === store.getters.userId)
      return "color: darkgreen";
    else
      return "color: darkorange";
  }

  public currentFriendId: string | null = null;
  public isActive(code: string): boolean{
    return this.currentFriendId === code;
  }
  
  public loadingWishes = false;
  public wishesError: string | null = null;
  public async loadWishes(code: string){
    this.loadingWishes = true;
    this.currentFriendId = code;

    let response = await this.$apiClient.getFriendWishes(this.currentFriendId);
    if (!response.isSuccess) {
      this.wishesError = response.message;
    }
    else {
      this.wishes = response.data;
    }

    this.loadingWishes = false;
  }
  
  public wishesFields = [
    {
      key: "name",
      label: "Желание",
      sortable: false
    },
    {
      key: "descrition",
      label: "Описание",
      sortable: true
    },
    {
      key: "url",
      label: "Ссылка",
      sortable: true
    },
    {
      key: "bookedByUserId",
      label: "Забронированно",
      sortable: false
    },
    {
      key: "controlColumn",
      label: "",
      sortable: false,
    },
  ];


  private isShowRequest = false;
  private requestMessage = '';
  private requestError: string | null = null;
  
  public showRequest(){
    this.requestError = null;
    this.requestMessage = '';
    this.isShowRequest = true;
  }
  
  public async sendRequest(){
    let response = await this.$apiClient.sendFriendRequest({
      message: this.requestMessage,
      id: this.foundUser!.id
    });
    
    if (!response.isSuccess){
      this.requestError = response.message;
      return;
    }
    
    this.isShowRequest = false;
  }
  
  private isShowSearchFriend = false;
  private searchEmail = '';
  private searchError: string | null = null;
  private foundUser: UserInfo | null = null;
  
  public showSearchFriend() {
    this.searchError = null;
    this.searchEmail = '';
    this.isShowSearchFriend = true;
    this.foundUser = null;
  }
  
  public async startSearch(){
    let response = await this.$apiClient.search(this.searchEmail);
    if (!response.isSuccess){
      this.foundUser = null;
      this.searchError = response.message;
      return;
    }
    else {
      this.searchError = null;
      this.foundUser = response.data;
    }
  }

  truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength - 3) + '...';
  }
  
}
