















import { Component, Vue } from "vue-property-decorator";
import HeaderControl from '@/components/HeaderControl.vue';
import store from "@/store";
import MenuControl from "@/components/MenuControl.vue";
@Component({
    components: {
      HeaderControl,
      MenuControl
    },
})
export default class App extends Vue {
  isLogined(): boolean{
    return  store.getters.isLogined;
  }
}
