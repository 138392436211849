import _Vue from 'vue';
import axios, { AxiosInstance } from 'axios';

declare module 'vue/types/vue' {
    interface VueConstructor {
        axios: AxiosInstance;
    }
    interface Vue {
        $axios: AxiosInstance;
    }
}

export function AxiosPlugin(Vue: typeof _Vue): void {
    const instance = axios.create();

    Vue.axios = instance;
    Vue.prototype.$dataSource = instance;
}
