import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';

import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import {AxiosPlugin} from "@/plugins/axios-plugin";
import {ApiProviderPlugin, ApiClientPluginOptions} from "@/plugins/api-client";


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(AxiosPlugin);
Vue.use<ApiClientPluginOptions>(ApiProviderPlugin, { store});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


