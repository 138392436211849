import _Vue from 'vue';
import ApiClient from "@/api/apiClient";

declare module 'vue/types/vue' {
    interface VueConstructor {
        apiClient: ApiClient;
    }
    interface Vue {
        $apiClient: ApiClient;
    }
}

export interface ApiClientPluginOptions {
    store: any;
}

export function ApiProviderPlugin(Vue: typeof _Vue, option: ApiClientPluginOptions | undefined): void {
    if (option === undefined)
        throw new Error("ApiClientPluginOptions is null")
    
    const source = new ApiClient(option.store);
    
    Vue.apiClient = source;
    Vue.prototype.$apiClient = source;
}
