import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AboutView from "@/views/AboutView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import HomeView from "@/views/HomeView.vue";
import WishesView from "@/views/WishesView.vue";
import FriendsView from "@/views/FriendsView.vue";
import RequestView from "@/views/RequestView.vue";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/wishes',
    name: 'wishes',
    component: WishesView
  },
  {
    path: "/friends",
    name: "friends",
    component: FriendsView
  },
  {
    path: "/requests",
    name: "requests",
    component: RequestView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
