export interface TaskResultBase {
    isSuccess: boolean,
    message: string | null
}

export interface TaskResult<TData> extends TaskResultBase{
    data: TData | null
}

export default class TaskResultError implements TaskResultBase{
    
    constructor(message: string){
        this.isSuccess = false;
        this.message = message;
    }
    
    isSuccess: boolean;
    message: string | null;
    
}