




































































































import {Component, Vue} from "vue-property-decorator";
import {CreateUserRequest} from "@/dto/user";
import { v4 as UuidV4 } from 'uuid';

@Component({})
export default class RegisterView extends Vue {
  public newUser: CreateUserRequest = {
    name: '',
    birthDay: '',
    login: '',
    password: '',
    captchaText: '',
    captchaGuid: '',
  };
  
  public passCheck = "";
  public showPassWarning = false;
  public error: string | null = null;

  public async register(){
    if (this.newUser.password !== this.passCheck){
      this.showPassWarning = true;
      return;
    }

    this.newUser.captchaGuid = this.captchaGuid;
    
    let userResponse = await this.$apiClient.newUser(this.newUser);
    if (userResponse.isSuccess){
      await this.$router.replace( { name: "login" });
      return;
    }

    this.error = userResponse.message;
  }
  
  public returnBack(){
     this.$router.back();
  }

  captchaGuid = UuidV4();
  
  public getCaptcha() {
    const baseUrl = process.env.VUE_APP_CORE_URL;
    return (baseUrl + "/api/User/GetCaptcha?guid=" + this.captchaGuid);
  }
    
}
