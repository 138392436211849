










































































import store from "@/store";
import {Component, Vue} from "vue-property-decorator";
import {Wish} from "@/dto/wishes";

@Component({
  components: {},
  
  created(this: WishesView) {
    this.loadWishes();
  },
})
export default class WishesView extends Vue {
  public userName(): string {
    return  store.getters.userName
  }
  
  public wishes: Array<Wish> | null = null;
  public error: string | null = null;
  
  public async loadWishes(){
    let response = await this.$apiClient.load();
    console.log(response);
    if (!response.isSuccess){
      this.error = response.message;
      return;
    }

    this.error = null; 
    this.wishes = response.data;
  }

  private isShowRemoveWish = false
  private toRemove: Wish | null = null;
  
  public showRemoveWish(item: Wish) {
    this.toRemove = item;
    this.isShowRemoveWish = true;
  }

  truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength - 3) + '...';
  }
  
  private isShowBooked = false;
  public async removeWish(item: Wish){
    this.isShowRemoveWish = false;
    if (item.bookedByUserId){
      this.isShowBooked = true;
      return;
    }
    
    let response = await this.$apiClient.remove(item.id);
    console.log(response);
    if (!response.isSuccess){
      this.error = response.message;
      return;
    }
    
    await this.loadWishes();
  }
  
  public wishesFields = [
    {
      key: "name",
      label: "Желание",
      sortable: false
    },
    {
      key: "descrition",
      label: "Описание",
      sortable: true
    },
    {
      key: "url",
      label: "Ссылка",
      sortable: true
    },
    {
      key: "controlColumn",
      label: "",
      sortable: false,
    },
  ];
  
  private isModalOpen = false;
  private newWish = { name: '', description: '', url: null };
  public newWishError: string | null = null;
  
  public showNewWish() {
    this.newWishError = null;
    this.newWish = { name: '', description: '', url: null }
    this.isModalOpen = true;
  }
  
  public async createNewWish(){
    let response = await this.$apiClient.addNew(this.newWish);
    if (!response.isSuccess){
      this.newWishError = response.message;
      return;
    }
    else {
      this.isModalOpen = false;
      await this.loadWishes();
    }
  }
  
}
