export interface LoginRequest {
    login: string | null;
    password: string | null;
}

export class UserAndPass implements LoginRequest{
    constructor(data: string|null){
        if (data){
            try {
                const obj = JSON.parse(data);
                this.login = obj.login;
                this.password = obj.password;
            }
            catch{
                this.login = null;
                this.password = null;
            }
        }else{
            this.login = null;
            this.password = null;
        }
    }
    
    login: string | null;
    password: string | null;
    
}

export class UserData{
    constructor(token: string|null) {
        this.name = null;
        this.guid = null;
        this.role = null;
        if (!token){
            return;
        }

        try {
            const jwt = this.decodeToken(token);
            console.log(jwt);
            this.name = jwt.Login;
            this.guid = jwt.UserGuid;
            this.role = jwt.Role;
        }catch (e){
            console.log(e)
        }
    }
    
    public name: string | null;
    public role: string | null;
    public guid: string | null;

    private urlBase64Decode(str: string) {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public decodeToken(token: string | null) {
        if (token === null || token === ''){
            return {}; 
        }
        
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('JWT must have 3 parts');
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token');
        }
        return JSON.parse(decoded);
    }
}