


































import {Component, Vue} from "vue-property-decorator";
import {FriendRequestInfo} from "@/dto/friends";

@Component({
  components: {},
  
  created(this: RequestView) {
    this.loadFriendRequests();
  },
})
export default class RequestView extends Vue {
  
  public requests: Array<FriendRequestInfo> | null = null;
  public error: string | null = null;
  
  public async loadFriendRequests(){
    let response = await this.$apiClient.receivedFriendRequests();
    console.log(response);
    if (!response.isSuccess){
      this.error = response.message;
      return;
    }

    this.error = null; 
    this.requests = response.data;
  }

  public async accept(fromId: string){
    let response = await this.$apiClient.acceptFriendRequests(fromId);
    console.log(response);
    if (!response.isSuccess){
      this.error = response.message;
      return;
    }

    this.error = null;
    await this.loadFriendRequests();
  }
  
  
  public requestsFields = [
    {
      key: "userName",
      label: "Имя",
      sortable: true
    },
    {
      key: "message",
      label: "Сообщение",
      sortable: false
    },
    {
      key: "controlColumn",
      label: "",
      sortable: false,
    },
  ];
  
}
